import React from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";

type LazyImageProps = {
    src: string;
    alt: string;
    className?: string;
    lazyWrapperClassName?: string;
    lazy?: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const Image = (props: LazyImageProps) => {
    const {
        src,
        className,
        lazyWrapperClassName,
        alt,
        lazy = false,
        ...rest
    } = props;
    return (
        lazy
            ? <LazyLoadImage
            title={rest.title}
            className={className}
            width={rest.width}
            height={rest.height}
            wrapperClassName={lazyWrapperClassName}
            alt={alt}
            src={src}
            effect={'blur'}/>
            : <img src={src} alt={alt} className={className} {...rest}/>
    );
};